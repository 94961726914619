import { useRouter } from 'next/router';
import { useTranslation } from '@Lib/i18n';
import { useSelector } from 'react-redux';
import { LinkVariant } from '@isp/link';
import { ImprintItem } from '@Umbraco/imprint';
import { RootState } from '@Redux';
import InternalLink from '@Shared/links/InternalLink';
import { PushDataToTagManagerForButtons } from '@Helpers/google-analytics/pushDataToTagManager';
import { getFooterRoute, getFooterRouteTemplate } from '@Helpers/footer/FooterTermsAndLegalDestinations';
import { Components, Pages, getComponentId } from '@Constants/google-analytics';
import { FooterGroups } from '@Constants/footer';
import './index.scss';

const FooterLegal = ({ customLegalRoute = null }) => {
	const footerGroups = useSelector<RootState, ImprintItem[]>(state => state.sections.footerContent.footerGroups) || [];
	const gmbhGroup = footerGroups?.find(group => group.key === FooterGroups.GMBH);
	const gmbhExcludedFooterGroups = footerGroups?.filter(group => group.key !== FooterGroups.GMBH);
	const router = useRouter();
	const { market } = router.query;
	const { t } = useTranslation(['common', 'footer']);

	return (
		<div className="ncj-footer__legal disp-flex">
			<InternalLink
				className="ncj-footer__links__items__link color-silver"
				key={gmbhGroup.key}
				href={getFooterRouteTemplate(gmbhGroup.key, customLegalRoute)}
				as={getFooterRoute(market as string, gmbhGroup.key, customLegalRoute)}
				inline
				id={getComponentId([Pages.GLOBAL, Components.FOOTER_GROUP, gmbhGroup.key])}
				onClick={e => PushDataToTagManagerForButtons((e.target as HTMLInputElement).id, window.location.href)}
				variant={LinkVariant.SECONDARY}
			>
				{gmbhGroup.title}
			</InternalLink>
			{gmbhExcludedFooterGroups.map(footerGroup => (
				<InternalLink
					key={footerGroup.key}
					href={getFooterRouteTemplate(footerGroup.key, customLegalRoute)}
					as={getFooterRoute(market as string, footerGroup.key, customLegalRoute)}
					inline
					id={getComponentId([Pages.GLOBAL, Components.FOOTER_GROUP, footerGroup.key])}
					onClick={e => PushDataToTagManagerForButtons((e.target as HTMLInputElement).id, window.location.href)}
					variant={LinkVariant.PRIMARY}
				>
					{footerGroup.title}
				</InternalLink>
			))}

			<InternalLink
				href={getFooterRouteTemplate(FooterGroups.COOKIES, customLegalRoute)}
				as={getFooterRoute(market as string, FooterGroups.COOKIES, customLegalRoute)}
				inline
				id={getComponentId([Pages.GLOBAL, Components.FOOTER_GROUP, FooterGroups.COOKIES])}
				onClick={e => PushDataToTagManagerForButtons((e.target as HTMLInputElement).id, window.location.href)}
				variant={LinkVariant.PRIMARY}
			>
				{t('footer:cookie-settings')}
			</InternalLink>
		</div>
	);
};

export default FooterLegal;
