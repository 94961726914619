import { useState } from 'react';
import { useRouter } from 'next/router';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import routes, { getRoutesAs } from '@Routes';
import Icon, { IconSize, IconType } from '@isp/icon';
import ISPLink, { LinkVariant } from '@isp/link';
import { useSelector } from 'react-redux';
import { RootState } from '@Redux';
import {
	PushDataToTagManagerForButtons,
	PushDataToTagManagerForExternalUrls,
	PushEventToDataLayer,
} from '@Helpers/google-analytics/pushDataToTagManager';
import InternalLink from '@Shared/links/InternalLink';
import { Events, WindowTargetAttributes } from '@Constants/common';
import {
	Components,
	Pages,
	getComponentId,
	TagManagerEventKeys,
	EventCategories,
	EventLocation,
	Buttons,
} from '@Constants/google-analytics';

const FooterLinkGroup = ({ group }) => {
	const isUserLoggedIn = useSelector<RootState, boolean>(state => state.user.isUserLoggedIn);
	const [collapsed, setCollapsed] = useState(true);

	const router = useRouter();
	const { market } = router.query;
	const { children, mobile, responsive, loggedInArea, hidden, mainGroup } = group || {};
	const loginSatisfied = !loggedInArea || isUserLoggedIn;
	const showLink = !hidden && loginSatisfied;
	const hasChildren = children && !isEmpty(children);
	const responsiveChildExist = !isEmpty(
		children?.filter(item => item?.responsive !== false && item?.url && !item.loggedInArea)
	);
	const showGroupInMobile = isUserLoggedIn || responsiveChildExist || (!hasChildren && responsive !== false);

	return (
		group &&
		showLink && (
			<div
				className="m-b-5 ncj-footer__links__wrapper"
				data-mobile={mobile}
				data-mobile-available={showGroupInMobile}
				data-main-group={mainGroup}
			>
				<div className="disp-flex train__center ncj-footer__links__group" onClick={() => setCollapsed(!collapsed)}>
					<GroupHeader group={group} />
					{hasChildren && (
						<div className="disp-flex train__center ncj-footer__links__group__arrow">
							<Icon size={IconSize.TINY} type={collapsed ? IconType.ARROW_DOWN : IconType.ARROW_UP} />
						</div>
					)}
				</div>
				{hasChildren && (
					<>
						<div className="disp-grid grid--item-gap-4 ncj-footer__links__items" data-collapsed={collapsed}>
							<LinkGroup links={children} market={market} mobile />
						</div>
						<LinkGroup className="m-v-4" links={children} market={market} />
					</>
				)}
				{!hasChildren && <div className="m-b-3" />}
			</div>
		)
	);
};

const GroupHeader = ({ group }) => {
	const router = useRouter();
	const { market } = router.query;
	const { label, url, blank, icon, id } = group || {};
	const isInternal = Object.values(routes).includes(url);

	const handleInternalLinkClick = e => {
		PushDataToTagManagerForButtons(e.target.id, window.location.href);
		PushEventToDataLayer(Events.CLICK, {
			category: EventCategories.NAVIGATION,
			location: EventLocation.FOOTER,
			label,
		});
	};

	const handleExtenalLinkClick = e => {
		PushDataToTagManagerForExternalUrls(
			TagManagerEventKeys.EXTERNAL_URL_BUTTON_CLICK,
			e.target.id,
			window.location.href,
			url
		);
		PushEventToDataLayer(Events.CLICK, {
			category: EventCategories.NAVIGATION,
			location: EventLocation.FOOTER,
			label,
		});
	};

	switch (true) {
		case !url:
			return <div className="color-white fs-16">{label}</div>;
		case isInternal:
			return (
				<InternalLink
					href={url}
					as={getRoutesAs(url, { market })}
					variant={LinkVariant.PRIMARY}
					className="color-white grid--item-gap-2"
					onClick={handleInternalLinkClick}
					id={id && getComponentId([Pages.GLOBAL, Components.FOOTER, Components.FOOTER_GROUP, id])}
				>
					{label}
					{icon}
				</InternalLink>
			);
		default:
			return (
				<ISPLink
					target={blank && WindowTargetAttributes.BLANK}
					className="color-white grid--item-gap-2"
					destination={getRoutesAs(url, { market })}
					onClick={handleExtenalLinkClick}
					id={id && getComponentId([Pages.GLOBAL, Components.FOOTER, Components.FOOTER_GROUP, id])}
				>
					{label}
					{icon}
				</ISPLink>
			);
	}
};

const LinkGroup = ({ className = '', links, market, mobile = false }) => {
	const isUserLoggedIn = useSelector<RootState, boolean>(state => state.user.isUserLoggedIn);

	const handleExternalLinkClick = (e, url, label) => {
		PushDataToTagManagerForExternalUrls(
			TagManagerEventKeys.EXTERNAL_URL_BUTTON_CLICK,
			e.target.id,
			window.location.href,
			url
		);
		PushEventToDataLayer(Events.CLICK, {
			category: EventCategories.NAVIGATION,
			location: EventLocation.FOOTER,
			label,
		});
	};

	const handleInternalLinkClick = (e, label) => {
		PushDataToTagManagerForButtons(e.target.id, window.location.href);
		PushEventToDataLayer(Events.CLICK, {
			category: EventCategories.NAVIGATION,
			location: EventLocation.FOOTER,
			label,
		});
	};

	const getFooterLinkId = (mobile, id) =>
		mobile
			? getComponentId([Pages.GLOBAL, Components.FOOTER, Buttons.MOBILE, id])
			: getComponentId([Pages.GLOBAL, Components.FOOTER, id]);

	return (
		<>
			{links
				?.filter(item => item?.url)
				.map((item, index) => {
					const { blank, id, url, label, responsive, loggedInArea } = item || {};
					const isInternal = Object.values(routes).includes(url);
					const loginSatisfied = !loggedInArea || isUserLoggedIn;

					switch (true) {
						case !item || !url || !loginSatisfied:
							return <></>;
						case isInternal:
							return (
								<InternalLink
									key={`footer-group-item-${index}`}
									href={url}
									as={getRoutesAs(url, { market })}
									target={blank && WindowTargetAttributes.BLANK}
									className={classNames(
										`ncj-footer__links__items__link${mobile ? '--mobile' : ''}`,
										'color-silver',
										{ hide: responsive === false },
										className
									)}
									id={id && getFooterLinkId(mobile, id)}
									onClick={e => handleInternalLinkClick(e, label)}
								>
									{label}
								</InternalLink>
							);
						default:
							return (
								<ISPLink
									key={`footer-group-item-${index}`}
									target={blank && WindowTargetAttributes.BLANK}
									className={classNames(
										`ncj-footer__links__items__link${mobile ? '--mobile' : ''}`,
										'color-silver',
										className
									)}
									destination={url}
									id={id && getFooterLinkId(mobile, id)}
									onClick={e => handleExternalLinkClick(e, url, label)}
								>
									{label}
								</ISPLink>
							);
					}
				})}
		</>
	);
};

export default FooterLinkGroup;
